<template>
  <q-list no-border>
    <q-item
      :class="$t('current_location') === selected && 'active'"
      clickable
      highlight
      @click.native="select({
        label: $t('current_location'),
        sublabel: '',
        value: currentLocation,
        place_id: ''
      })"
    >
      <q-item-section avatar>
        <q-avatar icon="fas fa-location" text-color="primary" />
      </q-item-section>

      <q-item-section>
        <q-item-label label>
          {{ $t('current_location') }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <template v-for="(address, index) in savedLocations">
      <q-separator v-if="!hasRole('backoffice') && !hasRole('admin')" :key="address.label+'sep'" />
      <q-item
        v-if="!hasRole('backoffice') && !hasRole('admin')"
        :key="address.label"
        :class="selected === 'Home' && 'active'"
        clickable
        separator
        highlight
        @click.native="select(savedLocations[index])"
      >
        <q-item-section avatar>
          <q-avatar icon="home" text-color="primary" />
        </q-item-section>

        <q-item-section>
          <q-item-label label>
            {{ address.label }}
          </q-item-label>
          <q-item-label
            caption
            class="address-text"
          >
            {{ address.text || address.formatted_address }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <q-separator v-if="!hasRole('backoffice') && !hasRole('admin') && recentLocations.length" />
    <q-item-label v-if="recentLocations.length" header inset>
      {{ $t('recent_locations') }}
    </q-item-label>
    <q-item
      v-for="loc in recentLocations"
      :key="loc.label"
      :class="loc.label === selected && 'active'"
      clickable
      separator
      highlight
      @click.native="select(loc)"
    >
      <q-item-section avatar>
        <q-avatar icon="timer" text-color="light" />
      </q-item-section>

      <q-item-section>
        <q-item-label label>
          {{ loc.label }}
        </q-item-label>
        <q-item-label caption>
          {{ loc.sublabel }}
        </q-item-label>
      </q-item-section>
    </q-item>

    <div v-if="showEmptyState">
      <m-empty-state
        :style="{padding: '30px', margin: 'auto'}"
        :font-size="16"
        icon="business"
      >
        {{ $t('tooltip.recent_locations') }}
      </m-empty-state>
    </div>
    <q-btn
      v-ripple
      color="primary"
      class="close"
      @click="$emit('close')"
    >
      {{ $t('close') }}
    </q-btn>
  </q-list>
</template>

<script>
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import { MEmptyState } from 'components/'

export default {
  components: { MEmptyState },
  mixins: [authentication],
  props: ['content', 'selected'],
  computed: {
    ...mapGetters({
      recentLocations: 'addresses/recent',
      savedLocations: 'addresses/saved',
      geoip: 'geoip',
      latLng: 'geolocate/latLng'
    }),
    currentLocation () {
      if (this?.latLng?.latitude && this?.latLng?.longitude) return `${this.latLng.latitude},${this.latLng.longitude}`
      return `${this.geoip.latitude},${this.geoip.longitude}`
    },
    showEmptyState () {
      return (
        (this.hasRole('backoffice') &&
          !this.hasRole('admin') &&
          !this.recentLocations.length) ||
        (!Object.keys(this.savedLocations).length &&
          !this.recentLocations.length)
      )
    }
  },

  methods: {
    select (a) {
      this.$emit('selected', a)
    },
    openMyWork () {
      this.$router.push({ hash: '/user-settings/address-list' })
    },
    openMyHome () {
      this.$router.push({ hash: '/user-settings/address-list' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.item {
  cursor: pointer;
}

strong {
  color: black;
  margin-left: 15px;
}

.address-text {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.3px;
}
.close
  position: sticky
  width: 100
</style>
