<template>
  <q-list no-border>
    <template v-for="(type, typeIndex) in content">
      <q-item-label v-if="type.title" :key="`${type.title}-${typeIndex}`" header inset>
        {{ type.title }}
      </q-item-label>
      <div
        v-for="(loc, locIndex) in type.list"
        :key="`${loc.label}-${locIndex}`"
        :style="{ height: 'auto' }"
        :class="loc.label === selected && 'active'"
        class="item"
        @click="handleClick(loc)"
      >
        <img v-if="loc.label.split('.')[2] && loc.context" height="24" :src="require('assets/what3words-search-listing.svg')" alt="what3words result" class="icon">
        <q-icon v-else :name="loc.icon" color="primary" size="20px" class="icon" />

        <div class="label">
          {{ loc.label }}
        </div>
        <div v-if="loc.country" class="caption">
          <span class="flag">{{ getFlag(loc.country) }}</span> {{ loc.context }}
        </div>
      </div>
    </template>
  </q-list>
</template>

<script>
import { details as wordGet } from 'api/what3words'
import { getFlag } from 'utils/flags'

export default {
  props: ['content', 'selected'],
  methods: {
    handleClick (i) {
      if (i.word) this.selectWords(i)
      else this.select(i)
    },
    select (i) {
      this.$emit('selected', i)
    },
    getFlag (countryCode) {
      return getFlag(countryCode)
    },
    selectWords (i) {
      return wordGet(i)
        .then(this.shapeWordResults)
        .then(r => {
          this.$emit('selected', r)
        })
    },
    shapeWordResults (res) {
      return {
        title: this.$t('results'),
        label: res.words,
        context: res.nearest_place,
        country: res.country.toLowerCase(),
        icon: res.icon || 'place',
        value: res.latitude + ',' + res.longitude || null,
        latlng: {
          lng: res.longitude || null,
          lat: res.latitude || null
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.item
  cursor pointer
  color #616161
  padding 15px 15px
  display grid
  grid-template-columns 36px auto
  grid-template-rows 1fr auto
  grid-template-areas "icon label" \ "caption caption"
  align-items center
  justify-content flext-start

.icon
  grid-area icon

.label
  grid-area label

.caption
  grid-area caption
  font-size 80%

.flag
  border-radius: 10px

</style>
