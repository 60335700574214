import http from './http'

export function query (query) {
  const result = []
  return http.get(`/locations/words/search`, {
    params: { query }
  })
    .then(response => {
      response.data.forEach(a => {
        result.push({
          country: a.country,
          nearest_place: a.nearest_place,
          words: a.words
        })
      })
      return result
    })
}

export function details (lookup) {
  return http.get('/locations/words', {
    params: { query: lookup }
  })
    .then(response => {
      return response.data[0]
    })
}
