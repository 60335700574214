<template>
  <div class="location-search">
    <q-input
      ref="input"
      v-model="searchText"
      :autofocus="autofocus"
      :label="label"
      class="input"
      :input-style="inputStyle"
      :stack-label="stackLabel"
      :placeholder="placeholder"
      autocomplete="new-password"
      :rounded="$q.platform.is.mobile"
      :outlined="$q.platform.is.mobile"
      :readonly="readonly"
      :dense="dense"
      :error="error"
      :clearable="clearable"
      :error-message="errorMessage"
      @focus="showList"
      @input="searchChanged"
      @paste.prevent="onPaste"
      @clear="$emit('selected', null)"
    >
      <template v-if="showIcon" v-slot:before>
        <q-icon v-if="isWhat3wordsResult" name="img:/w3w-slashes.png" />
        <q-icon v-else :name="icon || 'search'" size="22px" color="primary" />
      </template>
    </q-input>
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-if="open" :class="noShadow ? '' : 'shadow'" class="list">
        <q-scroll-area class="list-inner">
          <div v-if="loading" class="loading">
            <q-spinner :size="60" class="spinner" />
          </div>
          <list v-if="content" :content="content" :selected="value" @selected="itemSelected" />
          <saved-and-recent v-if="!noSaved" :selected="value" @selected="itemSelected" @close="hideList" />
        </q-scroll-area>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import list from './list'
import savedAndRecent from './saved-and-recent'
import { query as locationSearch } from 'api/location'
import { query as wordSearch } from 'api/what3words'

export default {
  components: { list, savedAndRecent },
  props: {
    label: String,
    value: Object,
    icon: String,
    autofocus: Boolean,
    noShadow: Boolean,
    stackLabel: Boolean,
    error: Boolean,
    dense: Boolean,
    errorMessage: String,
    inputStyle: [String, Object],
    clearable: Boolean,
    noSaved: {
      type: Boolean,
      default: false
    },
    keepOpen: Boolean,
    placeholder: String,
    query: {
      type: Function,
      default: locationSearch
    },
    branchOnly: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    isWhat3wordsEnabled: {
      type: Boolean,
      default: false
    },
    what3wordsQuery: {
      type: Function,
      default: wordSearch
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      results: null,
      isWhat3wordsResult: false,
      text: ''
    }
  },
  computed: {
    content () {
      return this.results
    },
    searchText: {
      get () {
        if (this.value && this.isWhat3wordsResult) {
          return '///' + this.text
        }
        return this.text
      },
      set (val) {
        this.text = val
      }
    }
  },
  watch: {
    open (val) {
      if (!val || this.keepOpen) return
      const outsideClickListener = event => {
        if (!this.$el.contains(event.target)) {
          this.open = false
          removeListeners()
        }
      }

      const tabListener = event => {
        if (event.keyCode === 9) {
          this.open = false
          removeListeners()
        }
      }

      const removeListeners = () => {
        document.body.removeEventListener('click', outsideClickListener)
        document.body.removeEventListener('keydown', outsideClickListener)
      }

      document.body.addEventListener('click', outsideClickListener)
      document.body.addEventListener('keydown', tabListener)
    },
    results (val) {
      if (val) {
        this.open = true
      }
    },
    value: {
      immediate: true,
      handler (val) {
        if (val) {
          this.searchText = val.label
        } else this.searchText = ''
      }
    }
  },
  methods: {
    onPaste () {
      this.$q.notify({ color: 'warning', message: this.$t('error.pasting_prevented') })
    },
    search (term) {
      return this.query(term)
        .then(this.shapeLocationResults)
        .catch(this.displayLocationErrorNotification)
    },
    shapeLocationResults (res) {
      return {
        results: {
          title: this.$t('results'),
          list: res.map(i => ({
            label: i.label,
            word: false,
            sublabel: '',
            value: i.value || i.code,
            icon: i.icon || 'place',
            latlng: {
              lat: i.lat,
              lng: i.lng
            },
            place_id: i.place_id,
            udprn: i.udprn
          }))
        }
      }
    },
    searchWords (term) {
      return this.what3wordsQuery(term)
        .then(this.shapeWordResults)
        .catch(this.displayLocationErrorNotification)
    },
    shapeWordResults (res) {
      return {
        results: {
          title: this.$t('results'),
          word: true,
          list: res.map(i => ({
            label: i.words,
            context: i.nearest_place,
            country: i.country.toLowerCase(),
            icon: i.icon || 'place',
            value: i.latitude + ',' + i.longitude || null,
            latlng: {
              lng: i.longitude || null,
              lat: i.latitude || null
            }
          }))
        }
      }
    },
    displayLocationErrorNotification (err) {
      console.log(err)
      this.$q.notify({ type: 'negative', message: this.$t('error.default') })
    },
    close () {
      this.$emit('close')
      this.open = false
    },
    showList (e) {
      this.$emit('open')
      if (e.target.value) {
        e.target.select()
      }
      if (this.noSaved) {
        if (this.results) {
          this.open = true
        }
        this.open = false
      } else {
        this.open = true
      }
    },
    hideList () {
      this.$emit('close')
      this.open = false
    },
    itemSelected (i) {
      this.searchText = i.label
      this.isWhat3wordsResult = i.label.split('.')[2] !== undefined
      this.$emit('input', i)
      this.$emit('selected', i)
      this.close()
    },
    searchChanged (val) {
      this.lookup()
    },
    lookup: _.debounce(function () {
      if (!this.searchText) {
        this.results = null
        return
      }

      this.loading = true

      if (!this.branchOnly && this.searchText.split('.')[2]) {
        return this.searchWords(this.searchText)
          .then(res => {
            this.loading = false
            this.results = res
          })
      } else {
        return this.search(this.searchText)
          .then(res => {
            this.loading = false
            this.results = res
          })
      }
    }, 300)
  }
}
</script>

<style lang="stylus" scoped>
.input
  margin-top 16px

.map-form .input, .search-btn .input
.q-item
  color #616161;

.drt-container .input, .map-form .input
  margin-top 0
  padding-bottom 0

.search-btn .input
  padding-bottom 0
  margin-top 0
  overflow hidden

.search-btn .q-field
  margin-bottom 0

.location-search
  position relative

.list
  position: absolute;
  background: white;
  z-index: 3000;
  width: 100%;
  height: 100%
  min-height 400px
  max-width 420px
  animation-duration: 0.3s
  left: 0
.list.shadow
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

.list-inner
  height: 100%

.loading
  position absolute
  display grid
  top 0
  left 0
  bottom 0
  width 100%
  background rgba(255, 255, 255, 0.9)
  z-index 3000
  animation-duration: 0.3s

.spinner
  margin: auto;
</style>
